<template>
  <!-- Register -->
  <div class="register">
    <el-form :model="user" ref="user" label-position="top" :rules="rules" status-icon>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop="name">
            <el-input v-model="user.name" placeholder="name" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="email">
            <el-input v-model="user.email" placeholder="email" />
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24">
          <el-form-item prop="password">
            <el-input type="password" v-model="user.password" autocomplete="off" placeholder="password"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24">
          <el-form-item prop="password_confirmation">
            <el-input type="password" v-model="user.password_confirmation" autocomplete="off" placeholder="password_confirmation"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item>
            <el-button type="primary" @click.prevent="register">Guardar</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      user: {
        name: "Ricardo",
        email: "ricardito.cristo@gmail.com",
        password: "24242186",
        password_confirmation: "24242186",
      },
    };
  },
  methods: {
    ...mapActions({
      registerUser: "Auth/registerUser",
    }),
    register() {
      this.registerUser(this.user);
    },
  },
};
</script>

<style>
</style>


